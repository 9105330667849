import wxFn from '../util/wxFn';

/*
 * @Date: 2021-10-18 11:44:25
 * @LastEditors: YuWenYun
 * @LastEditTime: 2021-12-15 18:31:47
 * @Description: 弹窗、地图相关逻辑
 * @version:
 * @Author: YuWenYun
 */
export default {
  methods: {
    /**
     * @description: 打开弹窗
     * @param {*} data 事件详情
     * @return {*}
     */

    openDialog(data) {
      let pageObj = this.h5Data.pages.find(item => {
        return item.uuid === data.linkId;
      });
      if (!pageObj) {
        this.$toast('弹窗不存在');
        return;
      }
      this.isShowDialog = !!Number(data.dialogRidio);

      this.dialogPage = pageObj;
      this.$nextTick(() => {
        this.$refs.popupComponentsTemplateEle.forEach(i => {
          if (i.element.elName === 'qk-video') {
            i.$children.forEach(item => {
              item.playVideo();
            });
          }
        });
      });
    },
    /**
     * @description: 是否点击磨蹭关闭弹窗
     * @param {*} item
     * @return {*}
     */

    backClck(item) {
      //
      if (item.hasOwnProperty('isClose') && item.isClose) {
        this.$refs.popup.close();
      }
    },
    /**
     * @description: 打开地图
     * @param {*}data 地图详情数据
     * @return {*}
     */

    openMap(data) {
      let { position } = data;
      let { title, location } = position;

      let ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        // 微信环境
        wx.ready(() => {
          wx.openLocation({
            latitude: location.lat, // 纬度，浮点数，范围为90 ~ -90
            longitude: location.lng, // 经度，浮点数，范围为180 ~ -180。
            name: title, // 位置名
            address: position.address, // 地址详情说明
            scale: 16 // 地图缩放级别,整型值,范围从1~28。默认为最大
          });
        });
      } else {
        let url = `https://apis.map.qq.com/tools/routeplan/eword=${title}&epointx=${location.lat}&epointy==${location.lng}?referer=h5&key=4UQBZ-SETLX-2PH4L-TQINK-KHTI6-GVB6T`;
        window.location.href = url;
      }
    }
  }
};
