/*
 * @Date: 2021-10-19 14:53:21
 * @LastEditors: YuWenYun
 * @LastEditTime: 2021-10-19 15:54:12
 * @Description:创建字体
 * @version:
 * @Author: YuWenYun
 */
export default {
  computed: {
    fontList() {
      let arr = [
        { font: 'aleo.ttf', id: 'aleo' },
        { id: 'hanInstrumentW', font: 'hanInstrumentW.ttf' },
        {
          id: 'huxiaobonanshenti',
          font: 'huxiaobonanshenti.otf'
        },
        {
          id: 'huxiaobosaobaoti',
          font: 'huxiaobosaobaoti.otf'
        },
        {
          id: 'huxiaobozhenshuaiti',
          font: 'huxiaobozhenshuaiti.otf'
        },
        {
          id: 'inkBlack',
          font: 'inkBlack.ttf'
        },
        {
          id: 'pangmenzhengdaobiaotiti',
          font: 'pangmenzhengdaobiaotiti.ttf'
        },
        {
          id: 'robotoBlack',
          font: 'robotoBlack.ttf'
        },
        {
          id: 'siyuansongtiBold',
          font: 'siyuansongtiBold.otf'
        },
        {
          id: 'siyuansongtiHeavy',
          font: 'siyuansongtiHeavy.otf'
        },
        {
          id: 'siyuansongtiLight',
          font: 'siyuansongtiLight.otf'
        },
        {
          id: 'siyuansongtiMedium',
          font: 'siyuansongtiMedium.otf'
        },
        {
          id: 'siyuansongtiRegular',
          font: 'siyuansongtiRegular.otf'
        },
        {
          id: 'siyuansongtiSemiBold',
          font: 'siyuansongtiSemiBold.otf'
        },
        {
          id: 'standingCoolblack',
          font: 'standingCoolblack.ttf'
        },
        {
          id: 'stationCoolHappyBody',
          font: 'stationCoolHappyBody.ttf'
        },
        {
          id: 'thickStrong',
          font: 'thickStrong.ttf'
        },
        {
          id: 'titleBlack',
          font: 'titleBlack.ttf'
        },
        {
          id: 'zikutangqingkaiti',
          font: 'zikutangqingkaiti.ttf'
        },
        {
          id: 'zitiquanxinyiguanheiti',
          font: 'zitiquanxinyiguanheiti.ttf'
        }
      ];
      let newArr = arr.map(i => {
        return {
          id: i.id,
          svgUrl: `https://osres.wangxiaobao.com/h5editor/svg/${i.id}.svg`,
          fontUrl: `https://osres.wangxiaobao.com/h5editor/font/${i.font}`
        };
      });
      return [
        {
          id: '',
          svgUrl: 'https://osres.wangxiaobao.com/h5editor/svg/normal.svg'
        },
        ...newArr
      ];
    }
  },
  data() {
    return {
      fontDownList: []
    };
  },
  methods: {
    /**
     * @description: 创建字体
     * @param {*}
     * @return {*}
     */

    createdFont() {
      this.h5Data.pages.forEach(page => {
        page.elements.forEach(i => {
          let flag = this.fontDownList.findIndex(el => i.commonStyle.fontFamily === el.id);
          // 拦截防止一直创建字体
          if (flag !== -1) {
            return;
          }
          let obj = this.fontList.find(item => {
            if (item.id === i.commonStyle.fontFamily) {
              this.fontDownList.push(item);
            }
            return item.id === i.commonStyle.fontFamily;
          });
          this.createFont(obj);
        });
      });
    },
    createFont(obj) {
      let style = document.createElement('style');
      style.type = 'text/css';
      style.innerText = `@font-face {font-family: ${obj.id};src: url('${obj.fontUrl}');}`;
      document.getElementsByTagName('head')[0].appendChild(style);
    }
  }
};
