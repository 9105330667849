/**
 *  元素点击事件相关方法
 * */
export default {
  methods: {
    async eventsClick (element) {
      return new Promise((resolve) => {
        switch (element['elName']) {
          case 'qk-image':
            console.log('图片点击事件')
            break
          case 'qk-image-carousel':
            console.log('幻灯片点击事件')
            break
          case 'qk-menu':
            console.log('经典菜单点击事件')
            break
          case 'qk-menuImg':
            console.log('图片菜单点击事件')
            break
          case 'qk-video':
            console.log('视频点击事件')
            break
          case 'qk-text':
            console.log('文本点击事件')
            break
        }
        resolve()
      })
    },
    /**
     * 链接跳转
     */
    _event_link (eventData) {
      console.log(eventData, '点击事件')
      return new Promise((resolve) => {
        if (eventData.url) {
          window.location.href = eventData.url
        }
        resolve()
      })
    },
    /**
     * 分享
     * @private
     */
    _event_share () {
      return new Promise((resolve) => {
        window.alert('分享')
        resolve()
      })
    },
    /**
     * 表单提交
     * @private
     */
    _event_submitForm (eventData, element, page) {
      return new Promise((resolve) => {
        // 获取提交接口
        // let apiUrl = eventData.url
        // 获取页面表单数据
        let formdata = {}
        page.pages.forEach(data => {
          data.elements.forEach(item => {
            if (!item.isForm) return
            let key = item.propsValue.keyName || ''
            formdata[key] = item.value
          })
        })
        console.log(formdata)
        resolve()
      })
    }
  }
}
