import qs from 'qs';

/**
 * 判断是否在数组内
 * @param con 验证内容
 * @param arr 验证数组
 * @param retVal 是否返回值
 * @param at {at}维数组
 * @param atKey 二维数组字段
 * @returns {string}
 */
export function inArray(con, arr, retVal = false, at = 1, atKey = 'id') {
  return new Promise(resolve => {
    at = Number(at);
    if (typeof con !== 'string' || typeof arr !== 'object') {
      resolve(retVal ? { index: -1, con: null } : -1);
      return false;
    }
    if (!con || !arr.length) {
      resolve(retVal ? { index: -1, con: null } : -1);
      return false;
    }
    if (at !== 1 && at !== 2) {
      resolve(retVal ? { index: -1, con: null } : -1);
      return false;
    }
    arr.forEach((r, i) => {
      switch (at) {
        case 2:
          if (r[atKey] === con) {
            resolve(retVal ? { index: i, con: r } : i);
            return false;
          }
          break;
        default:
          if (r === con) {
            resolve(retVal ? { index: i, con: r } : i);
            return false;
          }
          break;
      }
    });
    resolve(retVal ? { index: -1, con: null } : -1);
  });
}

/**
 * 生成随机字符串
 * @param len
 * @param data    二维数组、Map
 * @param field
 * @returns {string}
 */
export function randomStr(len = 10, data = [], field = 'id') {
  let $str = 'abcdefghijklmnopqrstuvwxyz1234567890';
  let maxPos = $str.length;
  let str = JSON.stringify(new Date().getTime());
  for (let i = 0; i < len; i++) {
    str += $str.charAt(Math.floor(Math.random() * maxPos));
  }
  if (data && typeof data === 'object') {
    if (Object.prototype.toString.call(data) === '[object Map]' || Object.prototype.toString.call(data) === '[object Array]') {
      data.forEach(item => {
        if (item[field] === str) {
          str = randomStr(len, data, field);
        }
      });
    }
  }
  return str;
}

/**
 * 时间戳=>时长
 * @param date
 * @returns {string}
 */
export function formatTime(date) {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  let hour = date.getHours();
  let minute = date.getMinutes();
  let second = date.getSeconds();

  let t1 = [year, month, day].map(formatNumber).join('/');
  let t2 = [hour, minute, second].map(formatNumber).join(':');

  return `${t1} ${t2}`;
}

/**
 * 秒转换为日时分秒和补零操作
 * @param s
 * @returns {string}
 */
export function secondsFormat(s) {
  let date = '';
  let hour = Math.floor(s / 3600);
  let minute = Math.floor((s - hour * 3600) / 60);
  let second = s - hour * 3600 - minute * 60;
  if (hour) {
    date += '' + (hour < 10 ? '0' + hour : hour);
  }
  if (date) {
    date += ':' + (minute < 10 ? '0' + minute : minute);
  } else {
    date += '' + (minute < 10 ? '0' + minute : minute);
  }
  if (date) {
    date += ':' + (second < 10 ? '0' + second : second);
  } else {
    date += '' + (second < 10 ? '0' + minute : second);
  }
  return date;
}

/**
 * 时间戳=>时间
 * @param time  时间戳
 * @param fmt   时间格式（默认：yyyy-MM-dd hh:mm:ss）
 * @returns {*}
 */
export function formatDate(time, fmt) {
  let date = time ? new Date(parseInt(time) * 1000) : new Date();
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  if (/(Y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, date.getFullYear() + '');
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
    }
  }
  return fmt;
}

/**
 * JSON串=>数组/对象
 * @param content
 * @param defData
 * @returns {Object|*}
 */
export function getArrOrObj(content, defData) {
  let data = defData;
  try {
    if (content) {
      switch (typeof content) {
        case 'string':
          data = JSON.parse(content);
          if (typeof data === 'string') {
            data = defData;
          }
          break;
        case 'object':
          data = content;
          break;
        default:
          break;
      }
    }
    return data;
  } catch (e) {
    return data;
  }
}

/**
 * 验证字符长度
 * @param content
 * @param min
 * @param max
 * @returns {boolean}
 */
export function checkLen(content, min = 2, max = 8) {
  if (typeof content !== 'string') {
    return false;
  }
  let len = 0;
  for (let i = 0; i < content.length; i++) {
    let c = content.charCodeAt(i);
    // 单字节加1
    if ((c >= 0x0001 && c <= 0x007e) || (c >= 0xff60 && c <= 0xff9f)) {
      len++;
    } else {
      len += 2;
    }
  }
  return !(len < min || len > max);
}

/**
 * 获取图片对象
 * @param url
 * @returns {HTMLImageElement}
 * @constructor
 */
export function FunctionGetImg(url) {
  let imgObj = new Image(); // 创建对象
  imgObj.src = url; // 改变图片地址
  return imgObj;
}

/**
 * 高精度计算
 * @type {{add: (function(*=, *=): number), div: (function(*=, *=): number), sub: (function(*=, *=): string), mul: (function(*=, *=): number)}}
 */
export const calculateFunc = {
  add: function(arg1, arg2) {
    return calculateAdd(arg1, arg2);
  },
  sub: function(arg1, arg2) {
    return calculateSub(arg1, arg2);
  },
  mul: function(arg1, arg2) {
    return calculateMul(arg1, arg2);
  },
  div: function(arg1, arg2) {
    return calculateDiv(arg1, arg2);
  }
};

/**
 * 加法函数，用来得到精确的加法结果
 * @param arg1
 * @param arg2
 * @returns {number}
 */
function calculateAdd(arg1, arg2) {
  arg2 = parseFloat(arg2);
  let r1, r2, m;
  try {
    r1 = arg1.toString().split('.')[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(100, Math.max(r1, r2));
  return (calculateMul(arg1, m) + calculateMul(arg2, m)) / m;
}

/**
 * 减法函数，用来得到精确的减法结果
 * @param arg1
 * @param arg2
 * @returns {string}
 */
function calculateSub(arg1, arg2) {
  arg1 = parseFloat(arg1);
  arg2 = parseFloat(arg2);
  let r1, r2, m, n;
  try {
    r1 = arg1.toString().split('.')[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2));
  // 动态控制精度长度
  n = r1 >= r2 ? r1 : r2;
  return ((calculateMul(arg1, m) - calculateMul(arg2, m)) / m).toFixed(n);
}

/**
 * 乘法函数，用来得到精确的乘法结果
 * @param arg1
 * @param arg2
 * @returns {number}
 */
function calculateMul(arg1, arg2) {
  arg1 = parseFloat(arg1);
  arg2 = parseFloat(arg2);
  let m = 0;
  let s1 = arg1.toString();
  let s2 = arg2.toString();
  try {
    m += s1.split('.')[1].length;
  } catch (e) {
    m += 0;
  }
  try {
    m += s2.split('.')[1].length;
  } catch (e) {
    m += 0;
  }
  return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m);
}

/**
 * 除法函数，用来得到精确的除法结果
 * @param arg1
 * @param arg2
 * @returns {number}
 */
function calculateDiv(arg1, arg2) {
  arg1 = parseFloat(arg1);
  arg2 = parseFloat(arg2);
  let t1 = 0;
  let t2 = 0;
  let r1, r2;
  try {
    t1 = arg1.toString().split('.')[1].length;
  } catch (e) {
    t1 = 0;
  }
  try {
    t2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    t2 = 0;
  }
  r1 = Number(arg1.toString().replace('.', ''));
  r2 = Number(arg2.toString().replace('.', ''));
  return calculateMul(r1 / r2, Math.pow(10, t2 - t1));
}

/**
 * 时间补0
 * @param n
 * @returns {string}
 */
function formatNumber(n) {
  let str = n.toString();
  return str[1] ? str : `0${str}`;
}

/**
 * 时间补00
 * @param str
 * @returns {string}
 */
function padLeftZero(str) {
  return ('00' + str).substr(str.length);
}
/**
 * @description: 操作url获取我们想要的入参
 * @param {*}
 * @return {*} 返回对象
 */
export function queryUrl() {
  let url = window.location.href.replace(/\/#\/|#\//g, '');
  let querys = url.split('?');
  return qs.parse(querys[1]);
}
