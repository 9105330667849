/*
 * @Date: 2021-11-09 18:05:13
 * @LastEditors: YuWenYun
 * @LastEditTime: 2022-03-17 14:32:35
 * @Description:
 * @version:
 * @Author: YuWenYun
 */
import request from '../../util/request';
const front = '/bff';
const service = '/martech-cme';
const actService = '/martech-activity';
const wxService = '/martech-wechat';

const version = 'v1';

export default {
  // 获取h5数据信息
  getPageData(data, hideLoading) {
    return request.get({ url: `${front}${service}/h5leaflet/${version}/detail-h5/${data}`, hideLoading });
  },
  // 获取预览数据
  getPreviewData(data, hideLoading = false) {
    // /h5leaflet/{version}/preview-h5/{entityId}
    return request.get({ url: `${front}${service}/h5leaflet/${version}/preview-h5/${data}`, hideLoading });
  },
  // 获取短信验证码
  getMessageVerify(data) {
    return request.get({ url: `${front}${service}/h5leaflet/${version}/send-message-code`, params: data });
  },
  // 获取用户信息
  getUserInfo(data) {
    return request.get({ url: `${front}/api/getInfo`, params: data });
  },
  // 提交留资组件表单数据
  submitFormData(data) {
    return request.post({
      url: `${front}${service}/h5leaflet/${version}/saveH5Submit`,
      formate: false,
      data
    });
  },
  // 正式作品投票的详细 以及投票的人的信息
  getUserVote(data) {
    return request.get({
      url: `${front}${service}/h5vote/${version}/getUserVote`,
      params: data
    });
  },
  // 投票
  toVote(data) {
    return request.post({
      url: `${front}${service}/h5vote/${version}/toVote`,
      formate: false,
      data
    });
  },

  // 查询活动类型 /v1/act/detail
  getActType(data) {
    return request.get({
      url: `${front}${actService}/${version}/act/detail`,
      params: data
    });
  },

  // 获取小程序URL Link
  postWxUrlLink(data) {
    return request.post({
      url: `${front}${wxService}/wechat/wx/mini-program/${version}/url-link/${data.wxAppId}`,
      formate: false,
      data
    });
  },
};
